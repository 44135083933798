import {
  Indicator as RadixProgressIndicator,
  Root as RadixProgressRoot,
  type ProgressProps as RadixProgressRootProps,
} from "@radix-ui/react-progress";
import { forwardRef } from "react";
import {
  progressIndicatorVariants,
  progressRootVariants,
  type ProgressRootVariants,
} from "./progress.styles";

// -----------------------------------------------------------------------------

export type ProgressRef = React.ElementRef<typeof RadixProgressIndicator>;

export interface ProgressProps
  extends RadixProgressRootProps,
    ProgressRootVariants {}

const Progress = forwardRef<ProgressRef, ProgressProps>(
  ({ className, palette, value, ...props }, ref) => {
    const pending = typeof value !== "number";
    return (
      <RadixProgressRoot
        {...props}
        value={value}
        className={progressRootVariants({ className, palette })}
        ref={ref}
      >
        <RadixProgressIndicator
          className={progressIndicatorVariants({ pending })}
          style={
            pending ? undefined : { transform: `translateX(-${100 - value}%)` }
          }
        />
      </RadixProgressRoot>
    );
  },
);

Progress.displayName = "Progress";

export { Progress };
