import { cva, type VariantProps } from "./style.utils";

// -----------------------------------------------------------------------------

export type FooterVariants = VariantProps<typeof footerVariants>;

export const footerVariants = cva({
  base: [
    // layout
    "grid grid-flow-row grid-cols-main md:grid-cols-main-md",
    // foreground
    "text-xs text-shade-70",
  ],
});

// -----------------------------------------------------------------------------

export type FooterNavVariants = VariantProps<typeof footerNavVariants>;

export const footerNavVariants = cva({
  base: [
    // layout
    "flex flex-wrap gap-x-30px gap-y-10px",
    // foreground
    "text-sm",
    // children focus/hover/active
    "hover:*:underline focus:*:underline active:*:underline",
  ],
});

// -----------------------------------------------------------------------------

export type CopyrightVariants = VariantProps<typeof copyrightVariants>;

export const copyrightVariants = cva({
  base: "*:whitespace-nowrap",
});
