import { LogoBairdMarkOnly } from "#ui/logo.components";
import { Progress } from "#ui/progress";

export default function AppProgressPending() {
  return (
    <div className="grid size-full flex-1 place-content-center bg-primary-120 text-white">
      <div className="grid w-200px place-items-center gap-30px">
        <LogoBairdMarkOnly palette="white" />
        <Progress
          value={null}
          getValueLabel={() => "Loading…"}
          palette="white"
        />
      </div>
    </div>
  );
}
