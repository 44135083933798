import { forwardRef } from "react";
import {
  copyrightVariants,
  footerNavVariants,
  footerVariants,
  type CopyrightVariants,
  type FooterNavVariants,
  type FooterVariants,
} from "./footer.styles";

// -----------------------------------------------------------------------------

export type FooterRef = React.ElementRef<"footer">;

export interface FooterProps
  extends React.ComponentPropsWithoutRef<"footer">,
    FooterVariants {}

const Footer = forwardRef<FooterRef, FooterProps>(
  ({ className, ...props }, ref) => {
    return (
      <footer {...props} className={footerVariants({ className })} ref={ref} />
    );
  },
);

Footer.displayName = "Footer";

export { Footer };

// -----------------------------------------------------------------------------

export type FooterNavRef = React.ElementRef<"nav">;

export interface FooterNavProps
  extends React.ComponentPropsWithoutRef<"nav">,
    FooterNavVariants {}

const FooterNav = forwardRef<FooterNavRef, FooterNavProps>(
  ({ className, ...props }, ref) => {
    return (
      <nav
        {...props}
        aria-label="Footer Navigation"
        className={footerNavVariants({ className })}
        ref={ref}
      />
    );
  },
);

FooterNav.displayName = "FooterNav";

export { FooterNav };

// -----------------------------------------------------------------------------

export type CopyrightRef = React.ElementRef<"div">;

export interface CopyrightProps
  extends Omit<React.ComponentPropsWithoutRef<"div">, "children">,
    CopyrightVariants {}

const Copyright = forwardRef<CopyrightRef, CopyrightProps>(
  ({ className, ...props }, ref) => {
    return (
      <div {...props} className={copyrightVariants({ className })} ref={ref}>
        <span>&copy; {new Date().getFullYear()}</span>{" "}
        <span>Robert W. Baird & Co. Incorporated.</span>{" "}
        <span>Member SIPC.</span>
      </div>
    );
  },
);

Copyright.displayName = "Copyright";

export { Copyright };
