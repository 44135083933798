import { cva, type VariantProps } from "./style.utils";

// -----------------------------------------------------------------------------

export type DialogOverlayVariants = VariantProps<typeof dialogOverlayVariants>;

export const dialogOverlayVariants = cva({
  base: [
    // layout
    "grid place-items-center overflow-y-auto",
    // positioning
    "fixed inset-0 z-50",
    // background
    "bg-overlay",
    // animation in
    "data-[state=open]:animate-in",
    "data-[state=open]:fade-in-0",
    // animation out
    "data-[state=closed]:animate-out",
    "data-[state=closed]:fade-out-0",
  ],
});

// -----------------------------------------------------------------------------

export type DialogContentVariants = VariantProps<typeof dialogContentVariants>;

export const dialogContentVariants = cva({
  base: [
    // layout
    "flex flex-col",
    "group/dialog @container/dialog",
    // background
    "bg-white",
    // focus unset outline
    "focus:outline-none",
  ],
  variants: {
    variant: {
      center: [
        // layout
        "h-fit max-h-dvh w-full max-w-full",
      ],
      "center-scroll-inner": [
        // layout
        "h-fit max-h-[85dvh] w-[calc(100vw_-_0.5rem_*_2)] max-w-2xl",
      ],
      "center-scroll-outer": [
        // layout
        "my-2 w-[calc(100vw_-_0.5rem_*_2)] max-w-2xl",
        // animation in/out is set via overlay
      ],
      fullscreen: [
        // layout
        "size-full max-h-dvh max-w-vw",
        // animation in
        "data-[state=open]:animate-in",
        "data-[state=open]:fade-in-0",
        // animation out
        "data-[state=closed]:animate-out",
        "data-[state=closed]:fade-out-0",
      ],
    },
  },
  compoundVariants: [
    {
      variant: ["center", "center-scroll-inner", "fullscreen"],
      className: [
        // positioning
        "fixed inset-0 z-50 m-auto",
      ],
    },
    {
      variant: ["center-scroll-inner", "center-scroll-outer"],
      className: [
        // radii/shadows
        "rounded-lg sm:rounded-xl",
        // borders
        "border border-shade-15",
      ],
    },
    {
      variant: ["center", "center-scroll-inner", "center-scroll-outer"],
      className: [
        // radii/shadows
        "shadow-lg",
      ],
    },
    {
      variant: ["center", "center-scroll-inner"],
      className: [
        // animation
        "duration-200",
        // animation in
        "data-[state=open]:animate-in",
        "data-[state=open]:fade-in-0",
        "data-[state=open]:zoom-in-95",
        "data-[state=open]:slide-in-from-bottom-1/2",
        // animation out
        "data-[state=closed]:animate-out",
        "data-[state=closed]:fade-out-0",
        "data-[state=closed]:zoom-out-95",
        "data-[state=closed]:slide-out-to-bottom-1/2",
      ],
    },
  ],
  defaultVariants: {
    variant: "center-scroll-inner",
  },
});

// -----------------------------------------------------------------------------

export type DialogHeaderVariants = VariantProps<typeof dialogHeaderVariants>;

export const dialogHeaderVariants = cva({
  base: [
    // layout
    "mx-20px grid min-h-56px items-center py-13px",
    "group-[[data-variant=fullscreen]]/dialog:min-h-64px",
    "group-[[data-variant=fullscreen]]/dialog:py-16px",
    // radii/shadow
    "rounded-t-lg md:rounded-t-xl",
    // border
    "border-b border-shade-15",
  ],
});

// -----------------------------------------------------------------------------

export type DialogMainVariants = VariantProps<typeof dialogMainVariants>;

export const dialogMainVariants = cva({
  base: [
    // layout
    "grow p-20px",
    "group-[[data-variant=center-scroll-inner]]/dialog:overflow-auto",
    "group-[[data-variant=fullscreen]]/dialog:overflow-auto",
  ],
});

// -----------------------------------------------------------------------------

export type DialogFooterVariants = VariantProps<typeof dialogFooterVariants>;

export const dialogFooterVariants = cva({
  base: [
    // layout
    "mx-20px flex flex-col gap-10px py-10px",
    "group-[[data-variant=fullscreen]]/dialog:py-20px",
    "@lg/dialog:flex-row @lg/dialog:items-center @lg/dialog:justify-end",
    // radii/shadow
    "rounded-b-lg sm:rounded-b-xl",
    // border
    "border-t border-shade-15",
  ],
});

// -----------------------------------------------------------------------------

export type DialogTitleVariants = VariantProps<typeof dialogTitleVariants>;

export const dialogTitleVariants = cva({
  base: "text-balance text-h5 font-normal leading-tight",
});

// -----------------------------------------------------------------------------

export type DialogDescriptionVariants = VariantProps<
  typeof dialogDescriptionVariants
>;

export const dialogDescriptionVariants = cva({
  base: "text-md font-semibold leading-tight text-shade-100",
});

// -----------------------------------------------------------------------------

export type DialogCloseIconButtonVariants = VariantProps<
  typeof dialogCloseIconButtonVariants
>;

export const dialogCloseIconButtonVariants = cva({
  base: [
    // layout
    "flex size-30px items-center justify-center",
    // positioning
    "absolute right-20px top-13px",
    // radii/shadows
    "rounded-full",
    // background
    "bg-shade-4",
    // foreground
    "text-shade-40",
    // animation
    "transition-colors",
    // focus/hover/active
    "hover:bg-shade-8 hover:text-shade-60",
    // focus unset outline
    "focus:outline-none",
    // focus reset outline (keyboard only)
    "focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-ring",
    // disabled
    "disabled:pointer-events-none",
  ],
});
