"use client";

import { useRequiredData } from "#app/(required)/useRequiredData";
import { Segment } from "#app/(unauthorized)/authentication/jwt";
import { useJwt } from "#app/(unauthorized)/authentication/useJwt";
import ErrorFallback from "#app/_ui/components/errors/error-fallback";
import { AuthorizedFooter } from "#app/_ui/components/footers/AuthorizedFooter";
import AutoLogoff from "#app/_ui/components/headers/autoLogoff/AutoLogoff";
import { Header } from "#app/_ui/components/headers/header";
import AppProgressPending from "#app/_ui/components/pages/AppProgressPending";
import { buildRedirectUrl } from "#app/lib/searchParamsUtil";
import { logTrace } from "@/lib/logger";
import { useIsRestoring } from "@tanstack/react-query";
import { redirect } from "next/navigation";
import { ErrorBoundary } from "react-error-boundary";
import devExConfig from 'devextreme/core/config'; 
import { licenseKey } from '#app/devextreme-license'; 

export default function SecuredLayout({
  children,
}: {
  children: React.ReactNode;
}) {

  devExConfig({ licenseKey });

  const isRestoring = useIsRestoring();
  const jwt = useJwt();
  const { isPendingRequiredData } = useRequiredData();

  if (jwt.segment == Segment.NotLoggedIn) {
    logTrace("Unauthenticated, redirecting...");
    return redirect(buildRedirectUrl());
  }
  if (isRestoring) {
    return null;
  }

  if (jwt.requiredActivities.length > 0 || isPendingRequiredData) {
    return <AppProgressPending />;
  }

  return (
    <>
      <AutoLogoff />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Header />
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          {children}
        </ErrorBoundary>
        <AuthorizedFooter />
      </ErrorBoundary>
    </>
  );
}
