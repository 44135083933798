"use client";

import {
  Close as RadixDialogClose,
  Content as RadixDialogContent,
  Description as RadixDialogDescription,
  Overlay as RadixDialogOverlay,
  Portal as RadixDialogPortal,
  Dialog as RadixDialogRoot,
  Title as RadixDialogTitle,
  Trigger as RadixDialogTrigger,
  type DialogCloseProps as RadixDialogCloseProps,
  type DialogContentProps as RadixDialogContentProps,
  type DialogDescriptionProps as RadixDialogDescriptionProps,
  type DialogOverlayProps as RadixDialogOverlayProps,
  type DialogPortalProps as RadixDialogPortalProps,
  type DialogProps as RadixDialogRootProps,
  type DialogTitleProps as RadixDialogTitleProps,
  type DialogTriggerProps as RadixDialogTriggerProps,
} from "@radix-ui/react-dialog";
import { forwardRef } from "react";
import {
  dialogCloseIconButtonVariants,
  dialogContentVariants,
  dialogDescriptionVariants,
  dialogFooterVariants,
  dialogHeaderVariants,
  dialogMainVariants,
  dialogOverlayVariants,
  dialogTitleVariants,
  type DialogCloseIconButtonVariants,
  type DialogContentVariants,
  type DialogDescriptionVariants,
  type DialogFooterVariants,
  type DialogHeaderVariants,
  type DialogMainVariants,
  type DialogOverlayVariants,
  type DialogTitleVariants,
} from "./dialog.styles";
import { Icon } from "./icon";

// -----------------------------------------------------------------------------

export type DialogRef = React.ElementRef<typeof RadixDialogRoot>;

export interface DialogProps extends RadixDialogRootProps {}

export const Dialog = RadixDialogRoot;

// -----------------------------------------------------------------------------

export type DialogTriggerRef = React.ElementRef<typeof RadixDialogTrigger>;

export interface DialogTriggerProps extends RadixDialogTriggerProps {}

export const DialogTrigger = RadixDialogTrigger;

// -----------------------------------------------------------------------------

export type DialogPortalRef = React.ElementRef<typeof RadixDialogPortal>;

export interface DialogPortalProps extends RadixDialogPortalProps {}

export const DialogPortal = RadixDialogPortal;

// -----------------------------------------------------------------------------

export type DialogCloseRef = React.ElementRef<typeof RadixDialogClose>;

export interface DialogCloseProps extends RadixDialogCloseProps {}

export const DialogClose = RadixDialogClose;

// -----------------------------------------------------------------------------

export type DialogCloseIconButtonRef = React.ElementRef<
  typeof RadixDialogClose
>;

export interface DialogCloseIconButtonProps
  extends Omit<RadixDialogCloseProps, "children">,
    DialogCloseIconButtonVariants {}

const DialogCloseIconButton = forwardRef<
  DialogCloseIconButtonRef,
  DialogCloseProps
>(({ className, ...props }, ref) => (
  <RadixDialogClose
    {...props}
    className={dialogCloseIconButtonVariants({ className })}
    ref={ref}
  >
    <Icon name="ms-close" />
    <span className="sr-only">Close</span>
  </RadixDialogClose>
));

DialogCloseIconButton.displayName = RadixDialogClose.displayName;

export { DialogCloseIconButton };

// -----------------------------------------------------------------------------

export type DialogOverlayRef = React.ElementRef<typeof RadixDialogOverlay>;

export interface DialogOverlayProps
  extends RadixDialogOverlayProps,
    DialogOverlayVariants {}

const DialogOverlay = forwardRef<DialogOverlayRef, DialogOverlayProps>(
  ({ className, ...props }, ref) => (
    <RadixDialogOverlay
      {...props}
      className={dialogOverlayVariants({ className })}
      ref={ref}
    />
  ),
);

DialogOverlay.displayName = RadixDialogOverlay.displayName;

export { DialogOverlay };

// -----------------------------------------------------------------------------

export type DialogContentRef = React.ElementRef<typeof RadixDialogContent>;

export interface DialogContentProps
  extends RadixDialogContentProps,
    DialogContentVariants {}

const DialogContent = forwardRef<DialogContentRef, DialogContentProps>(
  ({ className, variant, ...props }, ref) => (
    <RadixDialogContent
      {...props}
      data-variant={variant}
      className={dialogContentVariants({ className, variant })}
      ref={ref}
    />
  ),
);

DialogContent.displayName = RadixDialogContent.displayName;

export { DialogContent };

// -----------------------------------------------------------------------------

export type DialogHeaderRef = React.ElementRef<"div">;

export interface DialogHeaderProps
  extends React.ComponentPropsWithoutRef<"div">,
    DialogHeaderVariants {}

const DialogHeader = forwardRef<DialogHeaderRef, DialogHeaderProps>(
  ({ className, ...props }, ref) => (
    <div {...props} className={dialogHeaderVariants({ className })} ref={ref} />
  ),
);

DialogHeader.displayName = "DialogHeader";

export { DialogHeader };

// -----------------------------------------------------------------------------

export type DialogMainRef = React.ElementRef<"div">;

export interface DialogMainProps
  extends React.ComponentPropsWithoutRef<"div">,
    DialogMainVariants {}

const DialogMain = forwardRef<DialogMainRef, DialogMainProps>(
  ({ className, ...props }, ref) => (
    <div {...props} className={dialogMainVariants({ className })} ref={ref} />
  ),
);

DialogMain.displayName = "DialogMain";

export { DialogMain };

// -----------------------------------------------------------------------------

export type DialogFooterRef = React.ElementRef<"div">;

export interface DialogFooterProps
  extends React.ComponentPropsWithoutRef<"div">,
    DialogFooterVariants {}

const DialogFooter = forwardRef<DialogFooterRef, DialogFooterProps>(
  ({ className, ...props }, ref) => (
    <div {...props} className={dialogFooterVariants({ className })} ref={ref} />
  ),
);

DialogFooter.displayName = "DialogFooter";

export { DialogFooter };

// -----------------------------------------------------------------------------

export type DialogTitleRef = React.ElementRef<typeof RadixDialogTitle>;

export interface DialogTitleProps
  extends RadixDialogTitleProps,
    DialogTitleVariants {}

const DialogTitle = forwardRef<DialogTitleRef, DialogTitleProps>(
  ({ className, ...props }, ref) => (
    <RadixDialogTitle
      {...props}
      className={dialogTitleVariants({ className })}
      ref={ref}
    />
  ),
);

DialogTitle.displayName = RadixDialogTitle.displayName;

export { DialogTitle };

// -----------------------------------------------------------------------------

export type DialogDescriptionRef = React.ElementRef<
  typeof RadixDialogDescription
>;

export interface DialogDescriptionProps
  extends RadixDialogDescriptionProps,
    DialogDescriptionVariants {}

const DialogDescription = forwardRef<
  DialogDescriptionRef,
  DialogDescriptionProps
>(({ className, ...props }, ref) => (
  <RadixDialogDescription
    {...props}
    className={dialogDescriptionVariants({ className })}
    ref={ref}
  />
));

DialogDescription.displayName = RadixDialogDescription.displayName;

export { DialogDescription };
