import { cva, type VariantProps } from "./style.utils";

export type ProgressRootVariants = VariantProps<typeof progressRootVariants>;

export const progressRootVariants = cva({
  base: [
    "h-6px w-full overflow-hidden",
    // radii/shadows
    "rounded-full",
    // background
    "bg-black/[0.08]",
  ],
  variants: {
    palette: {
      unset: null,
      accent: "text-link-hover",
      error: "text-error-100",
      warning: "text-warning-100",
      success: "text-success-100",
      info: "text-info-100",
      neutral: "text-shade-100",
      white: [
        // background
        "bg-white/10",
        // foreground
        "text-white",
      ],
    },
  },
  defaultVariants: {
    palette: "accent",
  },
});

export type ProgressIndicatorVariants = VariantProps<
  typeof progressIndicatorVariants
>;

export const progressIndicatorVariants = cva({
  base: [
    // layout
    "size-full",
    // position
    "relative",
    // radii/shadows
    "rounded-full",
    // background
    "bg-current",
    // animation
    "transition-transform",
  ],
  variants: {
    pending: {
      false: null,
      // width is 1/6th, remainder 5/6ths (83.33%)
      true: "w-1/6 animate-progress-pending [--pong-distance:83.33%]",
    },
  },
  defaultVariants: {
    pending: false,
  },
});
