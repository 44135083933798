"use client";
import { useState, useEffect, useCallback } from "react";
import {
  createActivityTimer,
  startTimer,
  killTimer,
  resetTimer,
} from "@/lib/activityTimer";
import Countdown from "./Countdown";
import { throttle } from "lodash";
import { logTrace } from "@/lib/logger";
import { TimeSpan } from "@/lib/TimeSpan";
import config from "#app/env";
import { getCurrentJwt } from "#app/(unauthorized)/authentication/jwt";

export const activityTimerName = "Inactivity"; 

export default function AutoLogoff() {
  const [timedOut, setTimedOut] = useState<boolean>(false);
  

  const throttledResetTimer = useCallback(() => {
    return throttle(
      () => {
        if (timedOut) {
          return;
        }
        logTrace("AutoLogoff::User Activity Detected.");
        resetTimer(activityTimerName, {});
      },
      500,
      { leading: true, trailing: false },
    );
  }, [timedOut]);

  useEffect(() => {
    createActivityTimer({
      name: activityTimerName,
      getDelay: (_: any) => TimeSpan.fromSeconds(config.autoLogOffIdleTime),
      onTrigger: async (_: any) => {
        setTimedOut(true);
        return false;
      },
      failed: async (_: any) => {},
      stopped: () => {
        setTimedOut(false);
      },
    });
    startTimer(activityTimerName, {});
    return () => {
      killTimer(activityTimerName);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", throttledResetTimer(), false);
    document.addEventListener("keyup", throttledResetTimer(), false);
    document.addEventListener("click", throttledResetTimer(), false);

    return () => {
      document.removeEventListener("keydown", throttledResetTimer());
      document.removeEventListener("keyup", throttledResetTimer());
      document.removeEventListener("click", throttledResetTimer());
    };
  }, [throttledResetTimer]);

  function handleCountdownComplete() {
    logTrace("AutoLogoff::Handle on countdown Complete");
    const jwt = getCurrentJwt();
    jwt.logout(true);
  }

  function startAutoLogoffTimer() {
    logTrace("AutoLogoff::Starting AutoLogoff Timer");
    setTimedOut(false);
    resetTimer(activityTimerName, {});
  }

  return (
    <>
      {timedOut && (
        <div>
          Your Baird Online session will expire in
          <span>
            <Countdown
              onCountdownComplete={handleCountdownComplete}
              countdownTime={config.autoLogOffCountdownTime}
            ></Countdown>
          </span>{" "}
          seconds.
          <button onClick={startAutoLogoffTimer}>
            Click here to continue using Baird Online.
          </button>
        </div>
      )}
    </>
  );
}
