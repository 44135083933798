'use client';

import { logError } from "#app/lib/logger";
import { useErrorBoundary } from "react-error-boundary";

export default function ErrorFallback({ error }: { error: Error }) {
  const { resetBoundary } = useErrorBoundary();
  logError(error);
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
      <button onClick={resetBoundary}>Try again</button>
    </div>
  );
}