import { logTrace } from "@/lib/logger";
import { useEffect, useRef, useState } from "react";

export type CountdownProps = {
    onCountdownComplete: () => void,
    countdownTime: number
}

export default function Countdown({ onCountdownComplete, countdownTime }: CountdownProps) {
    const [value, setValue] = useState<number>(countdownTime);
    const interval = useRef<NodeJS.Timeout | null>();
    
    useEffect(() =>{
        if (value <=  0) {
            logTrace('Countdown complete');
            clearInterval(interval.current!);
            interval.current = null;
            onCountdownComplete();
        }
    },[value, onCountdownComplete]);

    useEffect(() => {
        logTrace('Countdown start');
        interval.current = setInterval(() => {
            setValue(value => { var newValue = value -1; return newValue > -1 ?  newValue : 0 });
        }, 1000);
        
        return () => {
            logTrace('Countdown cleanup');
            if (interval.current) {
                clearInterval(interval.current);
            }
        }
    }, []);
    return (<>{value}</>)
}